export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "account__smsToken--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7-Digit Code"])},
        "account__smsToken--resend-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
        "onboarding__accountCreate--smsCodePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXXXXXX"])}
      }
    }
  })
}
