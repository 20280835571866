<i18n>
[
    "login__phoneSignInCode--title",
    "account__smsToken--sub-header",
    "account__smsToken--resend-code",
    "onboarding__smsResend--success",
    "global__submit",
]
</i18n>

<template>
    <div class="c-phone-login-code">
        <LoginContainer
            :title="$t('login__phoneSignInCode--title')"
            :subtitle="$t('account__smsToken--sub-header', [phoneNumber])"
            :onSubmit="onSubmit"
            :errorText="serviceErrorMessage"
            :isLoaded="isLoaded"
            :submitButton="{
                class: 'u-spacer--5',
                text: $t('global__submit'),
            }"
        >
            <template #form-field>
                <SmsTokenInput
                    ref="smsTokenInput"
                    v-model="smsToken"
                    :shouldAutoFocus="true"
                    class="u-spacer--5"
                    :successText="successText"
                    :triggerValidation="triggerValidation"
                    @resend-sms-token="resendSmsToken"
                    @input="hideResendSuccessMessage"
                />
            </template>
        </LoginContainer>
    </div>
</template>

<script>

import { get } from 'lodash-es';
import { mapActions, mapGetters, mapState } from 'vuex';

import { genericRouteNames } from '~coreModules/core/js/router-constants';
import { LOGIN_MODULE_NAME, SUBMIT_PHONE_LOGIN, SEND_VERIFICATION_SMS } from '~coreRoutes/login/js/login-store';

import LoginContainer from '~coreRoutes/login/components/LoginContainer.vue';
import SmsTokenInput from '~coreModules/core/components/ui/form-elements/text-inputs/SmsTokenInput.vue';

export default {
    name: 'LoginPhoneVerify',
    components: {
        LoginContainer,
        SmsTokenInput,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (!vm.phoneNumber) {
                vm.$router.replace({ name: genericRouteNames.login.phone.base });
            }
        });
    },
    data() {
        return {
            smsToken: '',
            serviceErrorMessage: '',
            resendSmsTokenStatus: false,
            triggerValidation: false,
            isLoaded: false,
        };
    },
    computed: {
        ...mapState(LOGIN_MODULE_NAME, {
            phoneNumber: state => state.phoneNumber,
        }),
        ...mapGetters([
            'appBaseUrl',
        ]),
        ...mapState([
            'loginRedirectUrl',
        ]),
        successText() {
            return this.resendSmsTokenStatus ? this.$t('onboarding__smsResend--success') : '';
        },
    },
    methods: {
        ...mapActions(LOGIN_MODULE_NAME, {
            submitPhoneLogin: SUBMIT_PHONE_LOGIN,
            sendVerificationSms: SEND_VERIFICATION_SMS,
        }),
        onSubmit() {
            return this.hideResendSuccessMessage()
                .then(() => {
                    this.submitPhoneLogin(this.smsToken)
                        .then(() => {
                            this.isLoaded = true;
                            window.location = this.loginRedirectUrl || this.appBaseUrl;
                        })
                        .catch((error) => {
                            this.$refs.smsTokenInput.clearTokenField();
                            this.serviceErrorMessage = get(error, 'data.message', '');
                        });
                });
        },
        resendSmsToken() {
            this.sendVerificationSms(this.phoneNumber)
                .then(() => {
                    this.resendSmsTokenStatus = true;
                })
                .catch((error) => {
                    this.serviceErrorMessage = get(error, 'data.message', '');
                });
        },
        hideResendSuccessMessage() {
            if (this.smsToken === '') {
                this.triggerValidation = true;
            }
            this.resendSmsTokenStatus = false;

            return Promise.resolve();
        },
    },
};
</script>

<style lang="scss">

</style>
