export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "login__phoneSignInCode--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Your Verification Code"])},
        "account__smsToken--sub-header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We sent a verification code to ", _interpolate(_list(0)), ". Please enter it below"])},
        "account__smsToken--resend-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
        "onboarding__smsResend--success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully sent"])},
        "global__submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
      }
    }
  })
}
