<i18n>
[
    "account__smsToken--label",
    "account__smsToken--resend-code",
    "onboarding__accountCreate--smsCodePlaceholder",
]
</i18n>

<template>
    <BaseInput
        ref="input"
        v-model="smsToken"
        name="smsToken"
        inputType="tel"
        :rules="{ required: triggerValidation, digits: 7 }"
        :isRequired="true"
        :inlineButton="{
            text: $t('account__smsToken--resend-code'),
            hasImage: false
        }"
        :labelText="$t('account__smsToken--label')"
        :helperText="$t('onboarding__accountCreate--smsCodePlaceholder')"
        :successText="successText"
        :shouldAutoFocus="shouldAutoFocus"
        :maxLength="7"
        autocomplete="one-time-code"
        @inline-button-click="resendSmsToken"
    />
</template>

<script>
import BaseInput from '~coreModules/core/components/ui/form-elements/text-inputs/BaseInput.vue';

export default {
    name: 'SmsTokenInput',
    components: { BaseInput },
    props: {
        successText: {
            type: String,
            default: '',
        },
        triggerValidation: {
            type: Boolean,
            default: true,
        },
        shouldAutoFocus: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['resend-sms-token'],
    data() {
        return {
            smsToken: '',
        };
    },
    methods: {
        clearTokenField() {
            this.smsToken = '';
            this.$refs.input.inputValue = '';
            this.focus();
        },
        resendSmsToken(e) {
            this.$emit('resend-sms-token', e);
        },
        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>

<style lang="scss">

</style>
